h1{
    color:black;
}

h2{
    color:black;
    font-weight: 600;
}

h3{
    color: black;
    font-weight: 600;
}

.horizontalLine{
    margin: 0;
}

.projectBlock{
    border:1px solid rgba(240,240,240,0.2);
    break-inside: avoid;
}

.project{
    color:black;
    font-weight: 600;
}

.jobTitle{
    color:black;
}

.techStack {
    color:darkgray;
    font-weight: 600;
    font-size: smaller;
}

.bulletPoints{
    margin:0;
}

.contact-info {
    font-size: smaller;
}